// Extended type declaration for marquee element
declare module 'react' {
  namespace JSX {
    interface IntrinsicElements {
      marquee: React.DetailedHTMLProps<React.HTMLAttributes<HTMLMarqueeElement> & {
        scrollamount?: number | string;
        behavior?: 'scroll' | 'slide' | 'alternate';
        direction?: 'left' | 'right' | 'up' | 'down';
      }, HTMLMarqueeElement>;
    }
  }
}

import { useState, useEffect, useRef, useCallback } from 'react';
import { Twitch, Instagram, Twitter, Camera, Ghost, Cloud } from 'lucide-react';
import './index.css';

interface DuckStats {
  energy: number;
  happiness: number;
  hunger: number;
  lastFed: Date;
  age: number;
  level: number;
  experience: number;
}


interface Cell {
  isMine: boolean;
  isRevealed: boolean;
  isFlagged: boolean;
  neighborMines: number;
}

const MinesweeperGame: React.FC = () => {
  const [grid, setGrid] = useState<Cell[][]>([]);
  const [gameStatus, setGameStatus] = useState<'playing' | 'won' | 'lost'>('playing');
  const [firstClick, setFirstClick] = useState(true);
  const GRID_SIZE = 10;
  const MINES_COUNT = 10;

  useEffect(() => {
    initializeGrid();
  }, []);

  const initializeGrid = () => {
    const newGrid: Cell[][] = Array(GRID_SIZE).fill(null).map(() =>
      Array(GRID_SIZE).fill(null).map(() => ({
        isMine: false,
        isRevealed: false,
        isFlagged: false,
        neighborMines: 0
      }))
    );
    setGrid(newGrid);
    setGameStatus('playing');
    setFirstClick(true);
  };

  const placeMines = (firstRow: number, firstCol: number) => {
    let minesPlaced = 0;
    const newGrid = [...grid];
    
    while (minesPlaced < MINES_COUNT) {
      const row = Math.floor(Math.random() * GRID_SIZE);
      const col = Math.floor(Math.random() * GRID_SIZE);
      
      // Don't place mine on first click or where there's already a mine
      if (!newGrid[row][col].isMine && !(row === firstRow && col === firstCol)) {
        newGrid[row][col].isMine = true;
        minesPlaced++;
      }
    }

    // Calculate neighbor mines
    for (let row = 0; row < GRID_SIZE; row++) {
      for (let col = 0; col < GRID_SIZE; col++) {
        if (!newGrid[row][col].isMine) {
          let count = 0;
          for (let i = -1; i <= 1; i++) {
            for (let j = -1; j <= 1; j++) {
              if (row + i >= 0 && row + i < GRID_SIZE && 
                  col + j >= 0 && col + j < GRID_SIZE &&
                  newGrid[row + i][col + j].isMine) {
                count++;
              }
            }
          }
          newGrid[row][col].neighborMines = count;
        }
      }
    }

    setGrid(newGrid);
  };

  const revealCell = (row: number, col: number) => {
    if (gameStatus !== 'playing' || grid[row][col].isRevealed || grid[row][col].isFlagged) {
      return;
    }

    const newGrid = [...grid];

    if (firstClick) {
      placeMines(row, col);
      setFirstClick(false);
    }

    if (newGrid[row][col].isMine) {
      // Game Over
      newGrid[row][col].isRevealed = true;
      setGameStatus('lost');
      // Reveal all mines
      newGrid.forEach(row => row.forEach(cell => {
        if (cell.isMine) cell.isRevealed = true;
      }));
    } else {
      // Flood fill for empty cells
      const floodFill = (r: number, c: number) => {
        if (r < 0 || r >= GRID_SIZE || c < 0 || c >= GRID_SIZE || 
            newGrid[r][c].isRevealed || newGrid[r][c].isFlagged) {
          return;
        }

        newGrid[r][c].isRevealed = true;

        if (newGrid[r][c].neighborMines === 0) {
          for (let i = -1; i <= 1; i++) {
            for (let j = -1; j <= 1; j++) {
              floodFill(r + i, c + j);
            }
          }
        }
      };

      floodFill(row, col);
    }

    setGrid(newGrid);

    // Check for win
    const unrevealed = newGrid.flat().filter(cell => !cell.isRevealed).length;
    if (unrevealed === MINES_COUNT) {
      setGameStatus('won');
    }
  };

  const toggleFlag = (row: number, col: number, e: React.MouseEvent) => {
    e.preventDefault();
    if (gameStatus !== 'playing' || grid[row][col].isRevealed) {
      return;
    }

    const newGrid = [...grid];
    newGrid[row][col].isFlagged = !newGrid[row][col].isFlagged;
    setGrid(newGrid);
  };

  const getCellContent = (cell: Cell) => {
    if (!cell.isRevealed) {
      return cell.isFlagged ? '🚩' : '';
    }
    if (cell.isMine) {
      return '💣';
    }
    return cell.neighborMines || '';
  };

  const getCellColor = (cell: Cell) => {
    if (!cell.isRevealed) return 'bg-gray-400 hover:bg-gray-300';
    if (cell.isMine) return 'bg-red-500';
    return 'bg-gray-200';
  };

  const getNumberColor = (number: number) => {
    const colors = [
      'text-blue-600',   // 1
      'text-green-600',  // 2
      'text-red-600',    // 3
      'text-blue-900',   // 4
      'text-red-900',    // 5
      'text-teal-600',   // 6
      'text-black',      // 7
      'text-gray-600'    // 8
    ];
    return colors[number - 1] || '';
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
      <div className="bg-gray-100 p-4 rounded-lg">
        <div className="mb-4 flex justify-between items-center">
          <div className="text-lg font-bold">
            {gameStatus === 'won' ? '🎉 You Won!' : 
             gameStatus === 'lost' ? '💥 Game Over!' : 
             '😊 Minesweeper'}
          </div>
          <button 
            onClick={initializeGrid}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            New Game
          </button>
        </div>
        <div className="inline-block border-4 border-gray-300">
          {grid.map((row, rowIndex) => (
            <div key={rowIndex} className="flex">
              {row.map((cell, colIndex) => (
                <button
                  key={`${rowIndex}-${colIndex}`}
                  className={`w-8 h-8 flex items-center justify-center border border-gray-400 font-bold ${getCellColor(cell)} ${cell.neighborMines ? getNumberColor(cell.neighborMines) : ''}`}
                  onClick={() => revealCell(rowIndex, colIndex)}
                  onContextMenu={(e) => toggleFlag(rowIndex, colIndex, e)}
                  disabled={gameStatus !== 'playing' && !cell.isRevealed}
                >
                  {getCellContent(cell)}
                </button>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-2 text-sm text-gray-600">
          Right-click to flag mines • {MINES_COUNT - grid.flat().filter(cell => cell.isFlagged).length} mines left
        </div>
      </div>
    </div>
  );
};


const RainbowOverlay: React.FC = () => {
  useEffect(() => {
    // Add the styles to the document head
    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
      @keyframes rainbow {
        0% { background: rgba(255,0,0,0.1); }
        16.666% { background: rgba(255,165,0,0.1); }
        33.333% { background: rgba(255,255,0,0.1); }
        50% { background: rgba(0,255,0,0.1); }
        66.666% { background: rgba(0,0,255,0.1); }
        83.333% { background: rgba(75,0,130,0.1); }
        100% { background: rgba(238,130,238,0.1); }
      }
      .rainbow-overlay {
        animation: rainbow 2s linear infinite;
      }
    `;
    document.head.appendChild(styleSheet);

    // Cleanup
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none z-50 rainbow-overlay" />
  );
};

const MatrixRain: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const columns = canvas.width / 20;
    const drops: number[] = [];

    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    const chars = "DUCK🦆QUACK";

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0F0';
      ctx.font = '15px monospace';

      for (let i = 0; i < drops.length; i++) {
        const text = chars[Math.floor(Math.random() * chars.length)];
        ctx.fillText(text, i * 20, drops[i] * 20);

        if (drops[i] * 20 > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none z-40"
      style={{ opacity: 0.8 }}
    />
  );
};

const BlueScreenOfDeath: React.FC = () => {
  return (
    <div 
      className="fixed inset-0 bg-blue-700 text-white z-50 p-8 font-mono"
      onClick={() => document.location.reload()}
    >
      <div className="max-w-2xl mx-auto">
        <h1 className="text-center mb-8">Windows 98</h1>
        <p className="mb-4">
          A fatal exception 0E has occurred at 0028:C0011E36 in VXD VMM(01) +
          00010E36. The current application will be terminated.
        </p>
        <p className="mb-4">* Press any key to terminate the current application</p>
        <p className="mb-4">* Press CTRL+ALT+DEL to restart your computer. You will
          lose any unsaved information in all applications.</p>
        <div className="text-center mt-8 animate-pulse">
          Click anywhere to "restart" your computer
        </div>
      </div>
    </div>
  );
};


const DialupSound: React.FC = () => {
  useEffect(() => {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    let oscillator = audioContext.createOscillator();
    let gainNode = audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    // Simulate dial-up sound
    const playDialup = async () => {
      gainNode.gain.value = 0.1;
      
      // Beeping sounds
      for (let i = 0; i < 3; i++) {
        oscillator = audioContext.createOscillator();
        oscillator.connect(gainNode);
        oscillator.frequency.value = 1000 + (i * 200);
        oscillator.start();
        await new Promise(resolve => setTimeout(resolve, 100));
        oscillator.stop();
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // Dialing sound
      oscillator = audioContext.createOscillator();
      oscillator.connect(gainNode);
      oscillator.frequency.value = 500;
      oscillator.start();
      await new Promise(resolve => setTimeout(resolve, 1000));
      oscillator.stop();

      // Static sound
      for (let i = 0; i < 10; i++) {
        oscillator = audioContext.createOscillator();
        oscillator.connect(gainNode);
        oscillator.frequency.value = Math.random() * 2000 + 500;
        oscillator.start();
        await new Promise(resolve => setTimeout(resolve, 50));
        oscillator.stop();
      }
    };

    playDialup();

    return () => {
      gainNode.disconnect();
      if (oscillator) oscillator.disconnect();
    };
  }, []);

  return (
    <div className="fixed bottom-4 right-4 bg-black text-green-400 p-4 rounded font-mono z-50">
      Connecting to Internet...
      <div className="animate-pulse">Please wait...</div>
    </div>
  );
};



// Update the SecretSpots component to accept props
interface SecretSpotsProps {
  setEasterEggMode: (mode: string) => void;
  secretSpots: {[key: string]: boolean};
  setSecretSpots: React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>;
}




const CursorPet: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
  const [isQuacking, setIsQuacking] = useState(false);
  const animationFrameRef = useRef<number>(0);
  

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setTargetPosition({ 
        x: e.clientX + 30,  // Offset right
        y: e.clientY + 30   // Offset down
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const animate = () => {
      setPosition(current => {
        const dx = (targetPosition.x - current.x) * 0.1;
        const dy = (targetPosition.y - current.y) * 0.1;
        
        if (Math.random() < 0.002 && !isQuacking) {
          setIsQuacking(true);
          setTimeout(() => setIsQuacking(false), 800);
        }

        return {
          x: current.x + dx,
          y: current.y + dy
        };
      });
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [targetPosition, isQuacking]);

  const petStyle: React.CSSProperties = {
    position: 'fixed',
    left: `${position.x - 20}px`,
    top: `${position.y - 20}px`,
    pointerEvents: 'none',
    zIndex: 9999
  };

  return (
    <div style={petStyle}>
      <div className="text-4xl" style={{ filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.5))' }}>
        {isQuacking ? '🦆💬' : '🦆'}
      </div>
      {isQuacking && (
        <div className="absolute -top-8 left-0 bg-white text-black px-2 py-1 rounded-lg text-sm animate-bounce">
          QUACK!
        </div>
      )}
    </div>
  );
};


const RetroWebsite = () => {
  const [helperMessage, setHelperMessage] = useState<string>('');
  const [helperVisible, setHelperVisible] = useState<boolean>(false);
  const [helperPosition, setHelperPosition] = useState({ x: 100, y: 100 });
  const DuckHelper: React.FC = () => {
    const messages = [
      "Looks like you're trying to browse the web! Need help with that? 🦆",
      "Did you know you can type 'quack' for a special surprise? 🦆",
      "Want to see something cool? Try the Konami code! 🎮",
      "Have you signed my guestbook yet? 📝",
      "Try holding ALT to see some secret coordinates! 🎯",
      "Click around the page, you might find some hidden ducks! 🔍",
      "Type 'matrix' to see the truth... 🕶️",
      "Feeling nostalgic? Type 'connect' for a blast from the past! 📞",
      "Want to play a game? Type 'sweep' to find some ducks! 💣"
    ];

    useEffect(() => {
      const showRandomTip = () => {
        if (Math.random() < 0.3 && !helperVisible) { // 30% chance to show
          const newMessage = messages[Math.floor(Math.random() * messages.length)];
          setHelperMessage(newMessage);
          // Position in bottom right area
          setHelperPosition({
            x: window.innerWidth - Math.random() * 400 - 300, // Random position within 400px from right edge
            y: window.innerHeight - Math.random() * 200 - 200  // Random position within 200px from bottom
          });
          setHelperVisible(true);
          setTimeout(() => setHelperVisible(false), 8000); // Hide after 8 seconds
        }
      };
    
      const interval = setInterval(showRandomTip, 20000); // Check every 20 seconds
      return () => clearInterval(interval);
    }, [helperVisible]);

    if (!helperVisible) return null;

    return (
      <div 
        className="fixed bg-yellow-100 border-2 border-yellow-300 p-4 rounded-lg shadow-lg z-50 animate-bounce-slow"
        style={{ 
          left: helperPosition.x,
          top: helperPosition.y,
          maxWidth: '300px'
        }}
      >
        <div className="flex items-start gap-2">
          <div className="text-4xl animate-bounce">🦆</div>
          <div className="flex-1">
            <p className="text-black mb-2 font-comic">{helperMessage}</p>
            <button 
              onClick={() => setHelperVisible(false)}
              className="text-xs text-blue-600 hover:text-blue-800 underline"
            >
              Dismiss helper duck
            </button>
          </div>
        </div>
      </div>
    );
  };



  const SecretSpots: React.FC<SecretSpotsProps> = ({ 
    setEasterEggMode, 
    secretSpots, 
    setSecretSpots 
  }) => {
    const spots = [
      { id: 'rainbow', x: 50, y: 150, effect: '🌈', message: 'You found the rainbow pixel! Everything is more colorful now!' },
      { id: 'matrix', x: 250, y: 350, effect: '💻', message: 'You found the matrix pixel! Can you see the code?' },
      { id: 'party', x: 450, y: 250, effect: '🎉', message: 'Party mode activated! Time to celebrate!' },
      { id: 'sparkle', x: 150, y: 450, effect: '✨', message: 'Sparkles and magic everywhere!' },
      { id: 'secret', x: 350, y: 150, effect: '🎁', message: 'You found a super secret pixel! Here\'s a special duck: 🦆✨' }
    ];

    return (
      <div className="pointer-events-none">
        {spots.map(spot => (
          <div
            key={spot.id}
            className="fixed w-4 h-4 cursor-pointer opacity-0 hover:opacity-100 transition-opacity pointer-events-auto"
            style={{
              top: spot.y,
              left: spot.x,
              zIndex: 9999
            }}
            onClick={() => {
              if (!secretSpots[spot.id]) {
                setSecretSpots(prev => ({...prev, [spot.id]: true}));
                alert(spot.message);
                if (spot.id === 'rainbow') setEasterEggMode('rainbow');
                if (spot.id === 'matrix') setEasterEggMode('matrix');
              }
            }}
          >
            <span className="opacity-0 hover:opacity-100 transition-opacity">
              {spot.effect}
            </span>
          </div>
        ))}
      </div>
    );
  };
   // State declarations
   const [konamiProgress, setKonamiProgress] = useState<number>(0);
   const [typedKeys, setTypedKeys] = useState<string>('');
   const [debugKeys, setDebugKeys] = useState<string>('');
  const [easterEggMode, setEasterEggMode] = useState<string>(''); // For tracking active effects
  const [konamiIndex, setKonamiIndex] = useState(0);
  const [hiddenDucks, setHiddenDucks] = useState<{[key: string]: boolean}>({
  'duck1': false,
  'duck2': false,
  'duck3': false,
  'duck4': false,
  'duck5': false
});
const [showCoordinates, setShowCoordinates] = useState(false);
const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
const [secretSpots, setSecretSpots] = useState<{[key: string]: boolean}>({
  'rainbow': false,
  'matrix': false,
  'party': false,
  'sparkle': false,
  'secret': false
});

// Konami code sequence
const KONAMI_CODE = [
  'ArrowUp', 'ArrowUp', 
  'ArrowDown', 'ArrowDown', 
  'ArrowLeft', 'ArrowRight', 
  'ArrowLeft', 'ArrowRight', 
  'b', 'a'
];

const KONAMI_SEQUENCE = [
  'ArrowUp', 'ArrowUp',
  'ArrowDown', 'ArrowDown',
  'ArrowLeft', 'ArrowRight',
  'ArrowLeft', 'ArrowRight',
  'b', 'a'
];

// First, let's store our command list
const EASTER_EGG_COMMANDS = {
  'quack': () => {
    setQuackMode(prev => !prev);
    alert('🦆 QUACK MODE ' + (!quackMode ? 'ACTIVATED!' : 'DEACTIVATED!') + ' 🦆');
  },
  'matrix': () => setEasterEggMode('matrix'),
  'crash': () => setEasterEggMode('bsod'),
  'connect': () => setEasterEggMode('dialup'),
  'sweep': () => setEasterEggMode('minesweeper')
};



   const [duckActivity, setDuckActivity] = useState<string>('idle');
// Add random activities every few minutes:
// 'swimming' 🏊‍♂️, 'napping' 😴, 'exploring' 🔍, 'dancing' 💃, etc.

const [weather, setWeather] = useState<string>('sunny');
// Changes every hour: '☀️ Sunny', '🌧️ Rainy', '⛈️ Stormy', '🌈 Rainbow'
// Duck reacts differently to each weather

const DUCK_ACTIVITIES = [
  { status: 'idle', emoji: '🦆' },
  { status: 'swimming', emoji: '🏊‍♂️' },
  { status: 'napping', emoji: '😴' },
  { status: 'exploring', emoji: '🔍' },
  { status: 'dancing', emoji: '💃' },
  { status: 'coding', emoji: '💻' },
  { status: 'streaming', emoji: '🎥' },
  { status: 'blessing', emoji: '🙏' }
];

const WEATHER_TYPES = [
  { type: 'sunny', emoji: '☀️', message: 'Perfect day for swimming!' },
  { type: 'rainy', emoji: '🌧️', message: 'Ducks love the rain!' },
  { type: 'stormy', emoji: '⛈️', message: 'Taking shelter!' },
  { type: 'rainbow', emoji: '🌈', message: 'What a beautiful day!' }
];


  const [visitorCount, setVisitorCount] = useState('000,000,000');
  const [showThanks, setShowThanks] = useState(false);
  const [currentSong, setCurrentSong] = useState('midi1');
  const [duckStats, setDuckStats] = useState<DuckStats>({
    energy: 5,
    happiness: 7,
    hunger: 3,
    lastFed: new Date(),
    age: 0,
    level: 1,
    experience: 0
  });
  const [quackMode, setQuackMode] = useState(false);
  const [specialCursor, setSpecialCursor] = useState(false);
  const [birthdayChecked, setBirthdayChecked] = useState(false);
  const [foundSecretPixel, setFoundSecretPixel] = useState(false);
  const [pollVotes, setPollVotes] = useState({
    indie: 0,
    simulator: 0,
    coding: 0,
    wedding: 0
  });

  // Constants

  const [myStats, setMyStats] = useState(() => ({
    int: Math.floor(Math.random() * 10) + 10,
    wis: Math.floor(Math.random() * 10) + 10,
    cha: Math.floor(Math.random() * 10) + 10,
    str: Math.floor(Math.random() * 10) + 10,
    dex: Math.floor(Math.random() * 10) + 10,
    con: Math.floor(Math.random() * 10) + 10
  }));


  const today = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });

  // MIDI player styles
  const midiPlayerStyles: React.CSSProperties = {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    zIndex: 1000,
    width: '200px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)'
  };

// Add this effect for Konami code
useEffect(() => {
  const handleKonami = (e: KeyboardEvent) => {
    if (e.key === KONAMI_SEQUENCE[konamiProgress]) {
      setKonamiProgress(prev => prev + 1);
      if (konamiProgress === KONAMI_SEQUENCE.length - 1) {
        setEasterEggMode('rainbow');
        alert('🌈 SUPER DUCK MODE ACTIVATED! 🦆');
        setKonamiProgress(0);
      }
    } else {
      setKonamiProgress(0);
    }
  };

  window.addEventListener('keydown', handleKonami);
  return () => window.removeEventListener('keydown', handleKonami);
}, [konamiProgress]);


  useEffect(() => {
    let keyBuffer = '';
    const handleKeyPress = (e: KeyboardEvent) => {
      // Add the new key and keep last 20 characters
      keyBuffer = (keyBuffer + e.key.toLowerCase()).slice(-20);
      console.log('Buffer:', keyBuffer);
  
      // Check for any commands in the buffer
      Object.entries(EASTER_EGG_COMMANDS).forEach(([command, action]) => {
        if (keyBuffer.includes(command)) {
          action();
          keyBuffer = ''; // Clear buffer after command
          setTypedKeys(''); // Reset typed keys
        }
      });
    };
  
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [quackMode]);

  // Add this effect for handling Alt key and mouse position
useEffect(() => {
  const handleAltKey = (e: KeyboardEvent) => {
    if (e.key === 'Alt') {
      setShowCoordinates(e.type === 'keydown');
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  window.addEventListener('keydown', handleAltKey);
  window.addEventListener('keyup', handleAltKey);
  window.addEventListener('mousemove', handleMouseMove);

  return () => {
    window.removeEventListener('keydown', handleAltKey);
    window.removeEventListener('keyup', handleAltKey);
    window.removeEventListener('mousemove', handleMouseMove);
  };
}, []);
  
  // Mouse position tracker
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
  
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);
  
  // Duck activity display
  const CoordinatesDisplay: React.FC = () => {
    return showCoordinates ? (
      <div className="fixed bottom-0 right-0 bg-black text-green-400 font-mono text-xs p-1 z-50">
        X: {mousePosition.x} Y: {mousePosition.y}
      </div>
    ) : null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomActivity = DUCK_ACTIVITIES[Math.floor(Math.random() * DUCK_ACTIVITIES.length)];
      setDuckActivity(randomActivity.status);
    }, 30000); // Changes every 30 seconds
  
    return () => clearInterval(interval);
  }, []);
  
  // Weather change effect
  useEffect(() => {
    const interval = setInterval(() => {
      const randomWeather = WEATHER_TYPES[Math.floor(Math.random() * WEATHER_TYPES.length)];
      setWeather(randomWeather.type);
    }, 60000); // Changes every minute
  
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const randomNum = Math.floor(Math.random() * (999999 - 10000 + 1)) + 10000;
    const formattedNum = randomNum.toString().padStart(9, '0').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setVisitorCount(formattedNum);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuckStats(prev => {
        const timeSinceLastFed = (new Date().getTime() - new Date(prev.lastFed).getTime()) / (1000 * 60);
        
        return {
          ...prev,
          hunger: Math.min(10, prev.hunger + (timeSinceLastFed > 30 ? 1 : 0)),
          happiness: Math.max(0, prev.happiness - (prev.hunger > 7 ? 1 : 0)),
          energy: Math.max(0, prev.energy - (prev.hunger > 8 ? 1 : 0)),
          age: prev.age + 1
        };
      });
    }, 60000); // Updates every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      setTypedKeys(prev => {
        const newKeys = (prev + e.key).toLowerCase().slice(-5);
        if (newKeys === 'quack') {
          setQuackMode(!quackMode);
          alert('🦆 QUACK MODE ' + (!quackMode ? 'ACTIVATED!' : 'DEACTIVATED!') + ' 🦆');
        }
        return newKeys;
      });
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [quackMode]);

  useEffect(() => {
    const checkBirthday = () => {
      if (!birthdayChecked) {
        const today = new Date();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        
        if (month === 2 && day === 6) {
          alert('🎂 Happy Birthday to Ducky!! 🎂\nHere\'s a special birthday duck: 🦆✨');
          setBirthdayChecked(true);
        }
      }
    };

    checkBirthday();
  }, [birthdayChecked]);

  useEffect(() => {
    document.body.style.cursor = specialCursor ? 
      'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'32\' height=\'32\'><text y=\'20\'>🦆</text></svg>"), auto' :
      'default';
  }, [specialCursor]);

  // Handlers
  const feedDuck = () => {
    if (duckStats.hunger > 0) {
      setDuckStats(prev => {
        const newExperience = prev.experience + 10;
        const shouldLevelUp = newExperience >= prev.level * 100;
        
        return {
          ...prev,
          hunger: Math.max(0, prev.hunger - 2),
          happiness: Math.min(10, prev.happiness + 1),
          energy: Math.min(10, prev.energy + 1),
          lastFed: new Date(),
          level: shouldLevelUp ? prev.level + 1 : prev.level,
          experience: shouldLevelUp ? 0 : newExperience
        };
      });
    }
  };

  const handleVisitorCountDoubleClick = () => {
    setSpecialCursor(!specialCursor);
  };

  const handleSecretPixelClick = () => {
    setFoundSecretPixel(true);
    window.open('/secret-page.html', '_blank', 'width=500,height=500');
  };

  const handleGuestbookSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowThanks(true);
    alert("This form doesn't work, but thanks for trying. It's the thought that counts.");
  };

  const handleSongChange = (song: 'midi1' | 'midi2' | 'midi3') => {
    setCurrentSong(song);
  };

// JSX
return (
  <div className={`min-h-screen bg-black text-yellow-300 ${quackMode ? 'animate-bounce' : ''}`} style={{
    fontFamily: 'Comic Mono',
    backgroundImage: 'url("/api/placeholder/50/50")',
    backgroundRepeat: 'repeat'
  } as React.CSSProperties}>

      {/* Top Banner */}
      <div className="bg-blue-600 text-yellow-300 p-4 text-center border-b-4 border-yellow-300">
        <marquee scrollamount={5} className="text-2xl font-bold">
          🦆 Welcome to MrJDucky's Homepage! 🦆 Under Construction! 🚧 Please Sign My Guestbook! 📝
        </marquee>
      </div>

      {/* Main Content Table Layout */}
      {/* Floating MIDI Player */}
      <div style={midiPlayerStyles} className="bg-black border-2 border-yellow-300">
        <div className="bg-blue-600 p-2 text-center border-b-2 border-yellow-300 cursor-move">
          🎵 MIDI Jukebox 🎵
        </div>
        <div className="p-2 space-y-1">
          {['midi1', 'midi2', 'midi3'].map((song, index) => (
            <div 
              key={song}
              className={`p-1 cursor-pointer text-sm ${currentSong === song ? 'bg-blue-600' : 'hover:bg-blue-900'}`}
              onClick={() => handleSongChange(song as 'midi1' | 'midi2' | 'midi3')}
            >
              ▶️ {['Dancing Duck Theme', 'Quacking in the Rain', 'Pond Life Paradise'][index]}
            </div>
          ))}
        </div>
      </div>

      <table className="w-full">
        <tbody>
          <tr>
            {/* Left Sidebar */}
            <td className="w-48 bg-blue-800 p-4 border-r-4 border-yellow-300 align-top">
              {/* ... (Left sidebar content) ... */}
              <div className="text-center mb-8">
                <img src="https://i.imgur.com/gv3TitN.png" alt="MrJDucky" className="mx-auto border-4 border-yellow-300" />
                <div className="mt-2 animate-pulse">⭐ MrJDucky ⭐</div>
              </div>
              
              <div className="text-center mb-4">
                <div className="bg-red-600 text-white p-2 mb-2 border border-white">MENU</div>
                <div className="space-y-2">
                  {['Home', 'About Me', 'Content', 'Ministry', 'Cool Links'].map((item, index) => (
                    <a key={item} href={`#${item.toLowerCase().replace(' ', '')}`} className="block hover:bg-blue-600 p-1">
                      {['🏠', '👤', '🎮', '⛪', '🔗'][index]} {item}
                    </a>
                  ))}
                </div>
              </div>

              <div className="text-center mt-8 mb-4">
                <div className="mb-4">Visitor Count:</div>
                <div 
                  className="bg-black text-green-400 p-2 font-mono cursor-pointer" 
                  onDoubleClick={handleVisitorCountDoubleClick}
                >
                  {visitorCount}
                </div>
              </div>

              {/* Awards Section */}
              <div className="bg-black border-2 border-yellow-300 p-2 mt-4">
                <div className="bg-blue-600 p-1 mb-2">Site Awards</div>
                <div className="space-y-2">
                  <div className="border border-yellow-300 p-1 text-xs animate-pulse">
                    🏆 Best Use of Yellow 1998
                  </div>
                  <div className="border border-yellow-300 p-1 text-xs animate-pulse">
                    🦆 Most Ducks Per Page Award
                  </div>
                  <div className="border border-yellow-300 p-1 text-xs animate-pulse">
                    🎵 Outstanding MIDI Collection
                  </div>
                </div>
              </div>

              {/* Donate Box */}
<div className="bg-black border-2 border-yellow-300 p-2 mt-4">
  <div className="bg-blue-600 p-1 mb-2">
    <marquee scrollamount="2">💖 Support MrJDucky! 💖</marquee>
  </div>
  <div className="space-y-2 text-center">
    <div className="text-xs animate-pulse">
      Help feed the ducks! 🦆
    </div>
    <a 
      href="https://paypal.me/mrjducky" 
      target="_blank" 
      rel="noopener noreferrer"
      className="bg-blue-600 px-2 py-1 text-xs hover:bg-blue-700 block"
    >
      <span className="animate-pulse">plz don8</span> 🙏
    </a>
    <div className="text-[10px] text-gray-400">
      every duck counts!
    </div>
  </div>
</div>

            </td>  
            {/* Main Content */}
            <td className="p-4 bg-opacity-75 bg-blue-900 align-top">
              {/* About Section */}
              <div className="bg-black border-2 border-yellow-300 p-4 mb-8">
                <h1 className="text-center text-2xl mb-4 bg-blue-600 p-2">
                  🌟 Who is MrJDucky? 🌟
                </h1>
                <div className="space-y-4">
                  <p>
                    👋 Hey there! I'm a Scottish Content Creator, Community Manager, Software Developer, and Ordained Minister! 
                    I've been creating content since 2016, managing communities for various gaming projects, 
                    developing software solutions, and spreading joy through both technical and spiritual means!
                  </p>
                  <p>
                    Whether I'm coding a new project, managing a community event, creating content, or performing a wedding ceremony,
                    my goal remains the same - making people smile and bringing positivity to the world! 
                  </p>
                  <marquee scrollamount="3" className="text-red-500">
                    ⭐ Making people smile through tech, content, and spiritual guidance since the 90s! ⭐
                  </marquee>
                </div>
              </div>

              {/* Content Creation */}
              <div className="bg-black border-2 border-yellow-300 p-4 mb-8">
                <h2 className="text-center text-2xl mb-4 bg-blue-600 p-2">
                  🎮 Content Creation Zone 🎮
                </h2>
                <div className="text-center mb-4 flex justify-center space-x-4 flex-wrap">
  <a href="https://twitch.tv/MrJDucky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-purple-500 hover:underline p-2">
    <Twitch className="inline mr-2" size={24} />
    <span>Twitch</span>
  </a>
  
  <a href="https://tiktok.com/@mrjducky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-pink-500 hover:underline p-2">
    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
    </svg>
    <span>TikTok</span>
  </a>

  <a href="https://instagram.com/mrjducky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-pink-600 hover:underline p-2">
    <Instagram className="inline mr-2" size={24} />
    <span>Instagram</span>
  </a>

  <a href="https://twitter.com/mrjducky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-blue-400 hover:underline p-2">
    <Twitter className="inline mr-2" size={24} />
    <span>Twitter</span>
  </a>

  <a href="https://threads.net/@mrjducky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-white hover:underline p-2">
    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12.186 24h-.007c-3.581-.024-6.334-1.205-8.184-3.509C2.054 18.15 1.221 15.318 1.221 12c0-3.318.833-6.15 2.774-8.491C5.845 1.205 8.598.024 12.179 0h.007c3.579.024 6.332 1.205 8.182 3.509 1.941 2.341 2.774 5.173 2.774 8.491 0 3.318-.833 6.15-2.774 8.491-1.85 2.304-4.603 3.485-8.182 3.509zM8.03 11.777c0 1.983.445 3.511 1.322 4.541.865 1.017 2.131 1.533 3.77 1.533 1.638 0 2.904-.516 3.77-1.533.877-1.03 1.322-2.558 1.322-4.541 0-1.983-.445-3.511-1.322-4.541-.866-1.017-2.132-1.533-3.77-1.533-1.639 0-2.905.516-3.77 1.533-.877 1.03-1.322 2.558-1.322 4.541z"/>
    </svg>
    <span>Threads</span>
  </a>

  <a href="https://snapchat.com/add/mrjducky" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-yellow-400 hover:underline p-2">
    <Ghost className="inline mr-2" size={24} />
    <span>Snapchat</span>
  </a>

  <a href="https://bsky.app/profile/mrjducky.xyz" target="_blank" rel="noopener noreferrer" 
     className="flex items-center text-blue-500 hover:underline p-2">
    <Cloud className="inline mr-2" size={24} />
    <span>Bluesky</span>
  </a>
</div>
                <div className="mb-6">
                  <p className="mb-4">
                    As a variety content creator, I bring entertainment and engagement through multiple platforms. My regular Twitch streams are packed with interactive gameplay and exciting community events that keep viewers on the edge of their seats. Over on YouTube, you'll find a treasure trove of gaming content, insightful tech tutorials, and heartwarming community highlights that showcase the best of our vibrant community.
                  </p>
                  <p className="mb-4">
                    But that's not all! I'm always active across various social media platforms, ensuring we stay connected and sharing the latest updates. The crown jewel of my content creation are the community-driven events and collaborations, where we come together to create unforgettable experiences. Whether you're a long-time follower or new to the duck pond, there's always something exciting happening in our corner of the internet!
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4 text-center">
                  {['Simulators', 'Battle Royales', 'Indie Games', 'Interactive Streams'].map((item, index) => (
                    <div key={item} className="border border-yellow-300 p-2">
                      {['🎮', '⚔️', '🎲', '🎯'][index]} {item}
                    </div>
                  ))}
                </div>
              </div>

              {/* Ministry Section */}
              <div className="bg-black border-2 border-yellow-300 p-4 mb-8">
                <h2 className="text-center text-2xl mb-4 bg-blue-600 p-2">
                  ⛪ Ministry Services ⛪
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {[
                    { title: '🕊️ Universal Life Church', services: ['Wedding Ceremonies', 'Spiritual Guidance', 'Blessings'] },
                    { title: '🍝 Flying Spaghetti Monster', services: ['Pasta Ceremonies', 'Pirate Weddings', 'Noodly Blessings'] }
                  ].map((ministry, index) => (
                    <div key={index} className="border border-yellow-300 p-4">
                      <h3 className="text-xl mb-2">{ministry.title}</h3>
                      <ul className="list-disc ml-4">
                        {ministry.services.map((service, serviceIndex) => (
                          <li key={serviceIndex}>{service}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>

              {/* Guestbook Form */}
              <div className="bg-black border-2 border-yellow-300 p-4 mb-8">
                <h2 className="text-center text-2xl mb-4 bg-blue-600 p-2">
                  📝 Sign My Guestbook! 📝
                </h2>
                {!showThanks ? (
                  <form onSubmit={handleGuestbookSubmit} className="space-y-4">
                    {[
                      { label: 'Your Name:', type: 'text', required: true },
                      { label: 'Your Website:', type: 'text', placeholder: 'http://' },
                      { label: 'Your Message:', type: 'textarea', required: true, rows: 4 }
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block mb-2">{field.label}</label>
                        {field.type === 'textarea' ? (
                          <textarea className="w-full p-2 text-black" rows={field.rows} required={field.required}></textarea>
                        ) : (
                          <input type={field.type} className="w-full p-2 text-black" placeholder={field.placeholder} required={field.required} />
                        )}
                      </div>
                    ))}
                    <div className="text-center">
                      <button type="submit" className="bg-blue-600 px-4 py-2 hover:bg-blue-700">
                        💫 Sign Guestbook! 💫
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center p-4 bg-blue-900">
                    <h3 className="text-xl mb-2">🌟 Thanks for signing! 🌟</h3>
                    <p>Your message has been sent into the digital void!</p>
                  </div>
                )}
              </div>

              {/* Web Ring with Rick Roll */}
              <div className="bg-black border-2 border-yellow-300 p-4">
                <h2 className="text-center text-2xl mb-4 bg-blue-600 p-2">
                  💫 Duck Enthusiasts Web Ring 💫
                </h2>
                <div className="flex justify-center space-x-4">
                  <a 
                    href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="bg-blue-600 px-4 py-2 hover:bg-blue-700"
                  >
                    Visit Other Duck Sites →
                  </a>
                </div>
              </div>
            </td>

            {/* Right Sidebar */}
            <td className="w-64 bg-blue-800 p-4 border-l-4 border-yellow-300 align-top">
              <div className="text-center space-y-4">
                {/* Cool Stuff Header */}
                <div className="bg-red-600 text-white p-2 mb-2 border border-white animate-pulse">
                  ⭐ COOL STUFF! ⭐
                </div>
                {/* Duck Tamagotchi */}
                <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">Duck Tamagotchi</div>
                  <div className="text-sm space-y-2">
                    <div className="text-center text-2xl">
                      {duckStats.happiness > 7 ? '🦆' : duckStats.happiness > 4 ? '😐🦆' : '😢🦆'}
                    </div>
                    <div>Level: {duckStats.level}</div>
                    <div>Experience: [{'.'.repeat(Math.floor(duckStats.experience/10))}{'_'.repeat(10-Math.floor(duckStats.experience/10))}]</div>
                    <div>Energy: [{'.'.repeat(duckStats.energy)}{'_'.repeat(10-duckStats.energy)}]</div>
                    <div>Happiness: [{'.'.repeat(duckStats.happiness)}{'_'.repeat(10-duckStats.happiness)}]</div>
                    <div>Hunger: [{'.'.repeat(duckStats.hunger)}{'_'.repeat(10-duckStats.hunger)}]</div>
                    <button 
                      onClick={feedDuck}
                      className="bg-blue-600 px-2 py-1 text-xs hover:bg-blue-700 w-full"
                      disabled={duckStats.hunger === 0}
                    >
                      Feed Bread 🍞
                    </button>
                    
                  </div>
                </div>
                {/* Computer Facts */}
<div className="bg-black border-2 border-yellow-300 p-2">
  <div className="bg-blue-600 p-1 mb-2">90s Computer Facts</div>
  <marquee scrollamount="2" direction="up" className="h-20">
    {[
      '💾 A 56k modem actually connected at 53.3k',
      '🖥️ Windows 95 needed 4MB of RAM',
      '🎵 The first MP3 player held 6 songs',
      '📀 CD-ROM drives were 2X speed',
      '⌨️ Some say typing QUACK brings good luck...',
      '🎮 Old games had cheat codes like ↑↑↓↓←→←→BA',
      '📟 Type MATRIX to see the truth...',
      '💻 CRASH at your own risk!',
      '🌐 Type CONNECT to feel nostalgic',
      '💣 SWEEP the area for hidden treasures',
      '🔍 Hidden pixels might be anywhere...',
      '🎲 Duck-related words are always fun to type',
      '🚀 Some say ALT reveals secrets...',
      '🎯 Click around, you never know what you\'ll find!'
    ].map((fact, index) => (
      <div key={index} className="p-1">{fact}</div>
    ))}
  </marquee>
</div>

{/* MyStats */}
<div className="bg-black border-2 border-yellow-300 p-2">
  <div className="bg-blue-600 p-1 mb-2">MyStats</div>
  <div className="text-left text-sm">
    {[
      `Level: 30`,
      'Class: Duck Enthusiast',
      'Alignment: Chaotic Good',
      `Int: ${myStats.int}`,
      `Wis: ${myStats.wis}`,
      `Cha: ${myStats.cha}`,
      `Str: ${myStats.str}`,
      `Dex: ${myStats.dex}`,
      `Con: ${myStats.con}`
    ].map((stat, index) => (
      <div key={index}>{stat}</div>
    ))}
  </div>
</div>

                {/* Poll */}
                <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">Poll: Favorite Content?</div>
                  <div className="text-sm space-y-2">
                    {Object.entries(pollVotes).map(([key, value]) => (
                      <div key={key} className="flex items-center">
                        <button 
                          onClick={() => setPollVotes(prev => ({ ...prev, [key]: prev[key as keyof typeof pollVotes] + 1 }))}
                          className="bg-blue-600 px-2 py-1 text-xs hover:bg-blue-700 mr-2"
                        >
                          Vote
                        </button>
                        <span>{key.charAt(0).toUpperCase() + key.slice(1)}: {value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="bg-black border-2 border-yellow-300 p-2">
  <div className="bg-blue-600 p-1 mb-2">Duck Status</div>
  <div className="text-sm space-y-2">
    <div>
      Current Activity: {DUCK_ACTIVITIES.find(a => a.status === duckActivity)?.emoji} 
      {duckActivity.charAt(0).toUpperCase() + duckActivity.slice(1)}
    </div>
    <div>
      Weather: {WEATHER_TYPES.find(w => w.type === weather)?.emoji} 
      {WEATHER_TYPES.find(w => w.type === weather)?.message}
    </div>
  </div>
</div>



                {/* Latest Downloads */}
                <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">Latest Downloads</div>
                  <div className="text-left text-sm space-y-1">
                    <div>📥 duck_screensaver.exe (1.2MB)</div>
                    <div>📥 midi_collection.zip (342KB)</div>
                    <div>📥 webring_signup.txt (2KB)</div>
                  </div>
                </div>

 {/* Certification Corner */}
 <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">Certification Corner</div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="border border-yellow-300 p-1 text-xs">Web Design Lvl 3</div>
                    <div className="border border-yellow-300 p-1 text-xs">JavaScript Guru</div>
                    <div className="border border-yellow-300 p-1 text-xs">CSS Master</div>
                    <div className="border border-yellow-300 p-1 text-xs">Ordained Minister</div>
                    <div className="border border-yellow-300 p-1 text-xs">Professional Duck</div>
                  </div>
                </div>

              {/* Under Construction */}
              <div className="bg-black border-2 border-yellow-300 p-2 mt-4">
                <div className="bg-blue-600 p-1 mb-2">🚧 Under Construction 🚧</div>
                <div className="space-y-2 text-sm">
                  <div>
                    <div>Duck Blog</div>
                    <div className="bg-gray-800 h-4 w-full">
                      <div className="bg-yellow-300 h-full w-3/4"></div>
                    </div>
                  </div>
                  <div>
                    <div>Meme Gallery</div>
                    <div className="bg-gray-800 h-4 w-full">
                      <div className="bg-yellow-300 h-full w-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

                {/* Best Viewed With */}
                <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">Best Viewed With</div>
                  <div className="space-y-2">
                    <div className="text-sm">Resolution: 800x600</div>
                    <div className="text-green-400">Y2K COMPLIANT!</div>
                  </div>
                </div>

                {/* Today's Date */}
                <div className="bg-black border-2 border-yellow-300 p-2">
                  <div className="bg-blue-600 p-1 mb-2">🔄 Last Updated:</div>
                  <div className="text-center">{today}</div>
                </div>

{/* Hit Counter */}
<div className="bg-black border-2 border-yellow-300 p-2">
  <div className="bg-blue-600 p-1 mb-2">Site Statistics</div>
  <div className="text-sm space-y-1">
    <div>📊 Hits: {visitorCount}</div>
    <div>📝 Guestbook Entries: {showThanks ? 1 : 0}</div>
    <div className="animate-pulse">❤️ Made with love in Scotland</div>
  </div>
</div>


                {/* Secret Pixel */}
<div 
  className="fixed bottom-0 right-0 w-1 h-1 bg-transparent cursor-pointer" 
  onClick={handleSecretPixelClick}
  title="Nothing to see here..."
/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Add CursorPet here, before the footer */}
      <CursorPet />
      <DuckHelper />
      <SecretSpots 
  setEasterEggMode={setEasterEggMode} 
  secretSpots={secretSpots}
  setSecretSpots={setSecretSpots}
/>
      <CoordinatesDisplay />
      
      {/* Hidden Duck Spots */}
      {Object.keys(hiddenDucks).map((duckId, index) => (
        <div
          key={duckId}
          className="fixed w-4 h-4 cursor-pointer opacity-0 hover:opacity-100 transition-opacity"
          style={{
            top: `${100 + (index * 150)}px`,
            left: `${100 + (index * 200)}px`,
            zIndex: 9999
          }}
          onClick={() => {
            if (!hiddenDucks[duckId]) {
              setHiddenDucks(prev => ({...prev, [duckId]: true}));
              alert(`You found hidden duck #${index + 1}! 🦆✨`);
            }
          }}
        >
          🦆
        </div>
      ))}

      {/* Easter Egg Effects */}
      {easterEggMode === 'rainbow' && <RainbowOverlay />}
      {easterEggMode === 'matrix' && <MatrixRain />}
      {easterEggMode === 'bsod' && <BlueScreenOfDeath />}
      {easterEggMode === 'dialup' && <DialupSound />}
      {easterEggMode === 'minesweeper' && <MinesweeperGame />}

      {/* Footer */}
      <div className="bg-blue-600 text-yellow-300 p-4 text-center border-t-4 border-yellow-300">
        <div>© 2025 MrJDucky's Retro Website. All rights reserved.</div>
        <div className="text-sm mt-2">
          Best viewed with Netscape Navigator or Internet Explorer 4.0 at 800x600 resolution.
        </div>
      </div>
    </div>
    
  );
};

export default RetroWebsite;